.zigsin-header {
    width: 100%;
    /* background-color: black !important; */
    background: white !important;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: 2vw;
}

.zigsin-header-button {
    position: relative;
    background-color: transparent;
    color: #000052;
    font-weight: 600;
    margin-left: 1vw;
    padding: 5% 15% 5% 15%;
    border-color: transparent;
    font-size: 16px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.zigsin-header-button:hover {
    transform: translateY(-4px);
    cursor: pointer;
}

.zigsin-header-more-button {
    display: none;
    position: relative;
    color: #000052;
    border-radius: 50px;
    background-color: transparent;
    padding: 5% 8%;
    margin-left: 30%;
    border-color: transparent;
    float: right;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

@media only screen and (max-width: 768px) {
    .zigsin-header-more-button {
        display: flex;
    }
    .logo-text{
        font-size: 4vw !important;
    }
}

.zigsin-header-more-button:hover {
    transform: translateY(-4px);
    cursor: pointer;
}

.logo-text{
    color: #000052;
    font-size: 1.3vw;
}